import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Groupillustration from "../../static/images/GroupChat.svg"
import PreviewCommunity from "../../static/images/preview-community 1.png"
import PreviewHome from "../../static/images/preview-home 1.png"
import PreviewReport from "../../static/images/preview-report 1.png"
import AioTools from "../components/aioTools"
import Connections from "../components/connections"
import Cta from "../components/cta"
import Footer from "../components/footer"
import Ideas from "../components/ideas"
import Layout from "../components/layout"
import LogoCloud from "../components/LogoClouds"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import NewHero from "../components/hero/newHero";

const IndexPage = ({ location }) => {
    return (
        <Layout>
            <Seo title="Team Coaches" />
            <NavBar location={location} />
            <Container>
                <NewHero
                  title="Accelerator Management Software Built For Scaling"
                  description="No more spreadsheets and costly software, Team Coaches accelerator platform simplifies your startup program management."
                  image={Groupillustration}
                  altText="Group conversation"
                  isHome
                />
                <LogoCloud title="A proven platform trusted by customers worldwide" />
                <Connections
                    title="Save Time, Increase Growth, Build A Thriving Community"
                    description="Build, customize, and scale your innovation programs with the perfect set of tools. Accelerate your startup management, e-learning, mentoring/coaching, and administrative tasks."
                    imageSource={PreviewHome}
                />
                <AioTools
                    title="Manage and track your innovation program communities"
                    description="Bring people together under your program, with your content, announcements, and events. Make the startup community know each other. "
                    imageSource={PreviewCommunity}
                />
                <Row className="ideas-row p-3">
                    <Col lg={8} className="mx-auto p-3 text-center">
                        <p className="h1">
                            Built for Incubators, Accelerators, and Startup Studios
                        </p>
                        <p>
                            All the tools you need – from mentor matching and digital
                            curriculum, to CRM and room bookings – together in a single
                            platform. Tailor made for startup communities.
                        </p>
                    </Col>
                </Row>
                <Cta
                    backgroundColor="back-gray"
                    buttonColor="gray"
                    title="Best In Class Accelerator Management Software"
                    points={[
                        "Online mentoring and e-learning",
                        "OKRs, tasks, events, metrics, and reports",
                        "Available from any web-connected device",
                    ]}
                />
                <Ideas
                    title="Monitor important performance analytics"
                    description="Powerful dashboards and reporting tools that help you manage scheduling, engagement, and results with ease."
                    imageSource={PreviewReport}
                    direction="reverse"
                />
                <Row className="ideas-row p-3">
                    <Col lg={8} className="mx-auto p-3 text-center">
                        <p className="h1">Simplify your innovation process</p>
                        <p>
                            Give each member of your community the tools they need with a
                            personalized experience based on their needs.
                        </p>
                    </Col>
                </Row>
                <Footer />
            </Container>
        </Layout>
    )
}

export default IndexPage
