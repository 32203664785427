import React from "react"
import { Image, Row, Col } from "react-bootstrap"
// illustration
// styles
import "./styles.scss"

const index = ({ title, description, solution, direction, imageSource }) => {
  return (
    <Row
      className="aio-tools-row p-4 my-4"
      style={{ flexDirection: direction }}
    >
      <Col className="mx-auto p-3">
        {solution && <p className="mx-auto solutions">SOLUTIONS</p>}
        <p className="h1 mx-auto">{title}</p>
        <p className="mx-auto">{description}</p>
      </Col>
      <Col className="mx-auto">
        <Image src={imageSource} alt="Personal Data illustration" />
      </Col>
    </Row>
  )
}

export default index
