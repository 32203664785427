import React from "react"
import { Image, Row, Col } from "react-bootstrap"
// styles
import "./styles.scss"

const index = ({ title, description, imageSource, direction }) => {
  const orientation = direction === "reverse" ? "row-reverse" : "row"
  return (
    <Row className="ideas-row p-3" style={{ flexDirection: orientation }}>
      <Col className="mx-auto">
        <Image src={imageSource} rounded alt="" />
      </Col>
      <Col className="mx-auto p-3">
        <p className="h1">{title}</p>
        <p>{description}</p>
      </Col>
    </Row>
  )
}
export default index
