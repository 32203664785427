import React, {useContext} from "react"
import {Button, Col, Container, Image, Row} from "react-bootstrap"
import "./styles.scss"
import {ModalContext} from "../layout";

const Index = ({ title, description, imageSource, isHome }) => {
    const [, setShowModal] = useContext(ModalContext)
    const openModal = () => setShowModal(true)
    if(isHome) {
        return (
            <Row className="connection-row back-blue px-3 py-4 mx-0" >
                <Col>
                    <Container>
                        <Row>
                            <Col className="d-flex flex-column align-items-center py-5">
                                <h2 className="text-width text-center text-white" style={{ lineHeight: '3rem'}}>
                                    Top-in-class Community Management Platform for Coaches,<br/> Accelerators, Incubators, and Venture Capital
                                </h2>
                                <Button
                                    onClick={openModal}
                                    className="mt-4 py-2 text-nowrap fw-bold"
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#0066CC',
                                        width: '18%',
                                        borderRadius: '.5em',
                                        whiteSpace: 'pre',
                                        minWidth: '10em'
                                }}
                                >
                                    Let's Talk
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        )
    }
  return (
    <Row className="connection-row back-blue px-3 py-4">
      <Col className="mr-auto">
        <Image fluid rounded src={imageSource} alt="" />
      </Col>
      <Col className="mx-auto">
        <p className="h1 h1-white mx-auto">{title}</p>
        <p className="mx-auto">{description}</p>
      </Col>
    </Row>
  )
}

export default Index
